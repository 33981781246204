export const trackButton = label => {
  window?.ga('send', 'event', 'Button', label);
};

export const trackLink = label => {
  window?.ga('send', 'event', 'Link', label);
};

export const trackToggle = (label, value) => {
  window?.ga('send', 'event', 'Toggle', label, value);
};
