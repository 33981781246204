export const navLinks = [
  {
    label: 'Projects',
    pathname: '/',
    hash: '#project',
  },
  {
    label: 'Skills',
    pathname: '/',
    hash: '#skills',
  },
  {
    label: 'Articles',
    pathname: '/articles',
  },
  {
    label: 'About',
    pathname: '/',
    hash: '#about',
  },
];

export const socialLinks = [
  {
    label: 'Email',
    url: 'mailto:hello@saurabhlondhe.com',
    icon: 'email',
  },
  {
    label: 'Github',
    url: 'https://github.com/saurabhlondhe',
    icon: 'github',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/saurabh-londhe/',
    icon: 'linkedin',
  },
];
