import classNames from 'classnames';
import { useTheme } from 'components/ThemeProvider';

import faviconWhite from 'assets/icons/favicon-white.png';
import favicon from 'assets/icons/favicon.png';
import './index.css';
import React from 'react';

function Monogram({ highlight, className, ...props }) {
  const theme = useTheme();
  const [isDark, setTheme] = React.useState(theme);

  React.useEffect(() => {
    setTheme(theme);
  }, [theme]);

  return (
    <img
      src={isDark.themeId === 'dark' ? faviconWhite : favicon}
      alt="Logo"
      aria-hidden
      className={classNames('monogram', className)}
      width="40"
      height="45"
    />
  );
}

export default Monogram;
