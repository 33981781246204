import { lazy, Suspense, useEffect, createContext, useReducer, Fragment } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import ThemeProvider from 'components/ThemeProvider';
import VisuallyHidden from 'components/VisuallyHidden';
import { tokens } from 'components/ThemeProvider/theme';
import { msToNum } from 'utils/style';
import { useLocalStorage } from 'hooks';
import { initialState, reducer } from 'app/reducer';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import './reset.css';
import './index.css';
import { LinearProgress, withStyles } from '@material-ui/core';
import { useState } from 'react';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));
const GamifiedPortal = lazy(() => import('pages/GamifiedPortal'));
// const DealerPortal = lazy(() => import('pages/DealerPortal'));
const Articles = lazy(() => import('pages/Articles'));
const Uses = lazy(() => import('pages/Uses'));
const Page404 = lazy(() => import('pages/404'));

export const AppContext = createContext();
export const TransitionContext = createContext();
const BorderLinearProgress = withStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: 'rgb(var(--rgbPrimary))',
  },
}))(LinearProgress);

const repoPrompt = `\u00A9 2019-${new Date().getFullYear()} Saurabh Londhe\n\n Want to hire me? drop me a line at hello@saurabhlondhe.com`;

const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'dark');
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }

    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider themeId={state.theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

const AppRoutes = () => {
  const [scrollPercent, setPercent] = useState(0);
  const location = useLocation();
  const { pathname } = location;

  const updateDimensions = () => {
    let pixelsFromTop = window.scrollY;
    let documentHeight = document.body.clientHeight;
    let windowHeight = window.innerHeight;
    let difference = documentHeight - windowHeight;
    let percentage = Math.floor((100 * pixelsFromTop) / difference);
    setPercent(percentage);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateDimensions);

    return () => window.removeEventListener('scroll', updateDimensions);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={`https://saurabhlondhe.com${pathname}`} />
      </Helmet>
      <VisuallyHidden showOnFocus as="a" className="skip-to-main" href="#MainContent">
        Skip to main content
      </VisuallyHidden>
      <BorderLinearProgress
        variant="determinate"
        value={scrollPercent}
        className="static-bar"
        id="scroll-bar"
      />
      <Navbar location={location} />
      <TransitionGroup component="main" className="app" tabIndex={-1} id="MainContent">
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route path="/contact" component={Contact} />
                    <Route
                      path="/projects/emtec-candidate-engagement-system"
                      component={GamifiedPortal}
                    />
                    {/* <Route
                      path="/projects/kohler-dealer-portal"
                      component={DealerPortal}
                    /> */}
                    <Route path="/uses" component={Uses} />
                    <Route path="/articles" component={Articles} />
                    <Route component={Page404} />
                  </Switch>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

export default App;
